// eslint-disable-next-line @typescript-eslint/no-var-requires
const { i18next, languageDetector } = require('./src/i18n')

exports.onRouteUpdate = () => {
  const language = languageDetector.detect()
  if (i18next.language !== language) {
    i18next.changeLanguage(language)
  }

  const intercomContainer = document.getElementById('intercom-container')
  const intercomLightweightApp = document.getElementsByClassName('intercom-lightweight-app')

  if (
    window.location.pathname.includes('/blog/') ||
    window.location.pathname.includes('/book-a-demo/') ||
    window.location.pathname.includes('/product-learn/') ||
    window.location.pathname.includes('/referral-demo/') ||
    // unpublished on 4/9/2025:
    // window.location.pathname.includes('/product-digest/') ||
    window.location.pathname.includes('/resources/guides/') ||
    window.location.pathname.includes('/glossary/')
  ) {
    if (intercomContainer) {
      intercomContainer.setAttribute('style', 'display: none')
    }

    if (intercomLightweightApp && intercomLightweightApp.length) {
      intercomLightweightApp[0].setAttribute('style', 'display: none')
    }
  } else {
    if (
      intercomContainer &&
      intercomContainer.getAttribute('style') &&
      intercomContainer.getAttribute('style').includes('display: none')
    ) {
      intercomContainer.setAttribute('style', 'display: block')
    }

    if (
      intercomLightweightApp &&
      intercomLightweightApp.length &&
      intercomLightweightApp[0].getAttribute('style') &&
      intercomLightweightApp[0].getAttribute('style').includes('display: none')
    ) {
      intercomLightweightApp[0].setAttribute('style', 'display: block')
    }
  }
}

exports.onPreRouteUpdate = () => {
  if (!!window.Intercom) {
    window.Intercom('hide')
  }
}
